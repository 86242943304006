import { MayBeNull } from '@wpp-open/core'

import { pitchRNDApi } from 'api'
import { Wrike } from 'types/projects/wrike'

type CreateWrike = Pick<Wrike, 'id' | 'projectName' | 'tenantId'> & {
  wrikeFolderId?: MayBeNull<string>
  wrikeProjectUrl?: MayBeNull<string>
}

export const createProjectWrikeApi = (body: CreateWrike) => pitchRNDApi.post<Wrike>('/projects/wrike', body)
